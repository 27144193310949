import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import Api from './../../service/Api';
import BannerMain from './Components/BannerMain';
import Precio from './Components/Precio';
import ServiceCremacion from './Components/ServiceCremacion';
import CEREMONIA_DE_DESPEDIDA from './../../assets/images/Cremacion/CEREMONIA_DE_DESPEDIDA.png';
import ENTREGA_DE_ANFORA from './../../assets/images/Cremacion/ENTREGA_DE_ANFORA.png';
import SERVICIO_DE_CREMACION from './../../assets/images/Cremacion/SERVICIO_DE_CREMACION.png';
import TRAMITACION_SEREMI from './../../assets/images/Cremacion/TRAMITACION_SEREMI.png';
import './bnews_buy.scss';
import { Row } from 'reactstrap';

const CompraLinea2 = () => {
  const [, setItems] = useState({});
  const [tooltipOpen, settooltipOpen] = useState(false);
  const [product, setProduct] = useState({});
  const history = useHistory();
  let { id, codigo } = useParams();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  const onSetCar = () => {
    history.push('/compra/checkout', product);
  };

  const onNewSetCar = (product) => {
    history.push('/compra/checkout', product);
  };
  const [viewbutton, setViewButton] = useState(false);

  useEffect(() => {
    const buscarPlanes = async () => {
      const response = await Api.getProductByCode({
        codigo: codigo,
      });

      if (response.response === 200) {
        //setItems(response.result);
        setProduct(response.result[0]);
      }
    };
    buscarPlanes();
  }, [codigo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderDisclaimerIVA = () => {
    // <p className="disclaimer">
    //   <span style={{color: '#000',fontWeight: 'bold' , textAlign: 'justify' , opacity: '.5'}}>El precio indicado se encuentra afecto a</span> <span style={{color: '#000',fontWeight: 'bold' , textAlign: 'justify' }}>IVA A BENEFICIO FISCAL</span> <span style={{color: '#000',fontWeight: 'bold' , textAlign: 'justify' , opacity: '.5'}}>que Sendero se encuentra obligado a recaudar y entregar al Fisco.</span>
    // </p>

    const date = new Date();

    if (date.getFullYear() >= process.env.REACT_APP_IVAS) {
      return (
        <>
          <p style={{ marginBottom: '10px', fontWeight: 'bold !important' }}>
            <span style={{ fontWeight: 'bold', color: '#000' }}>
              ¿Tienes dudas? Haz click
            </span>{' '}
            <a
              style={{
                fontWeight: 'bold',
                color: '#000',
              }}
              href={
                'https://sendero-api.gux.cl//storage/ecom/preguntas_frecuentes.pdf'
              }
              rel="noopener noreferrer"
              target={'_blank'}
            >
              aquí
            </a>
          </p>
          <p className="disclaimer">
            <span
              style={{
                color: '#627171',
                fontWeight: 'bold',
                textAlign: 'justify',
                opacity: '.5',
              }}
            >
              El precio indicado se encuentra afecto a{' '}
            </span>{' '}
            <span
              style={{
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'justify',
              }}
            >
              IVA A BENEFICIO FISCAL
            </span>{' '}
            <span
              style={{
                color: '#627171',
                fontWeight: 'bold',
                textAlign: 'justify',
                opacity: '.5',
              }}
            >
              {' '}
              que Sendero se encuentra obligado a recaudar y enterar al Fisco.
            </span>
          </p>
        </>
      );
    }

    return <></>;
  };
  const searchProducts = async () => {
    try {
      const response = await Api.getAllProducts({});
      if (response.response === 200) {
        setLoading(false);

        setRecords(response.result);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    searchProducts();
  }, []);

  const renderButtons = () => {
    if (viewbutton === true) {
      const codeproduct = records
        .map((btn) => {
          if (btn.codigo === codigo) {
            if (parseInt(btn.parent_id) === 0) {
              return {
                indentificador: btn.id,
                product: btn,
                codigo: btn.codigo,
                title: btn.title,
                parent: false,
              };
            }
            if (parseInt(btn.parent_id) !== 0) {
              return {
                indentificador: btn.parent_id,
                product: btn,
                codigo: btn.codigo,
                title: btn.title,
                parent: true,
              };
            }
          }
        })
        .filter((notUndefined) => notUndefined !== undefined);

      // console.log(codeproduct);

      const botons = records.map((r) => {
        if (
          parseInt(codeproduct[0].indentificador) === parseInt(r.id) ||
          parseInt(codeproduct[0].indentificador) === parseInt(r.parent_id)
        ) {
          // if (String(codigo) === String(r.codigo)) {
          //   return (
          //     <>
          //       <button
          //         className="btn-included-others"
          //         onClick={() => {
          //           onSetCar();
          //           //setViewButton(!viewbutton);
          //         }}
          //       >
          //         Comprar
          //       </button>
          //     </>
          //   );
          // } else {
          return (
            <Row className="row-container_margin">
              <button
                className="btn-included-others"
                onClick={() => {
                  onNewSetCar(r);
                }}
              >
                Comprar{' '}
                {r.correlativo > 1 && r.parent_id > 0 ? r.correlativo : '1'}
                {r.correlativo > 1 && r.parent_id > 0
                  ? ( r.adicional > 0 ? ' ('+r.adicional+'% de descuento) ' : '' )
                  : ''}
              </button>
            </Row>
          );
        }
        // }
      });

      return botons;
    }
    return <></>;
  };
  return (
    <>
      <BannerMain />
      <div className="container product">
        {/* <div className="row justify-content-center align-items-center"> */}
        <div className="row">
          <div className="col-12 col-md-6">
            <img
              src={product.portada}
              alt={product.title}
              className="item-image img-fluid"
            />
            <div className="row mt-4">
              <div className="col-3">
                <img
                  src={CEREMONIA_DE_DESPEDIDA}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-3">
                <img src={ENTREGA_DE_ANFORA} alt="" className="img-fluid" />
              </div>
              <div className="col-3">
                <img src={SERVICIO_DE_CREMACION} alt="" className="img-fluid" />
              </div>
              <div className="col-3">
                <img src={TRAMITACION_SEREMI} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="info-producto">
              <h2>{product.title}</h2>
              <Precio {...product} />
              <div className="box-detalle mt-3 mb-4">
                {typeof product.detalle !== 'undefined' ? (
                  product.detalle.length > 0 &&
                  product.detalle.map((item, index) => (
                    <div
                      key={index}
                      className="box-detalle__icon_detalle"
                      dangerouslySetInnerHTML={{
                        __html: `${item.description_large}`,
                      }}
                    ></div>
                  ))
                ) : (
                  <div className="box-detalle__icon_detalle">
                    <p>No tiene descripción disponible</p>
                  </div>
                )}
              </div>
              <div className="box-detalle">
                <p className="tx-blue">Servicio incluye:</p>
                {typeof product.servicio !== 'undefined' ? (
                  product.servicio.length > 0 &&
                  product.servicio.map((item, index) => (
                    <>
                      <div
                        key={index}
                        className="box-detalle__icon_detalle"
                        dangerouslySetInnerHTML={{
                          __html: `${item.description}`,
                        }}
                      ></div>
                      <p>
                        Ánfora a disponibilidad del stock en el momento de
                        utilizar el servicio.
                      </p>
                      <br />
                      {renderDisclaimerIVA()}
                      {/* {
                          renderPrecioswithIVA(item, product)
                        } */}
                    </>
                  ))
                ) : (
                  <div className="box-detalle__icon_detalle">
                    <p>No tiene descripción disponible</p>
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-6">
                <button
                  className={`btn btn-blue ${
                    viewbutton === true ? 'btn-gray' : ''
                  }`}
                  onClick={() => {
                    // onSetCar();
                    //setViewButton(!viewbutton);
                    let box = document.getElementById('box');
                    if (box.classList.contains('hidden')) {
                      box.classList.remove('hidden');

                      setTimeout(function () {
                        box.classList.remove('visuallyhidden');
                        setViewButton(!viewbutton);
                      }, 200);
                    } else {
                      setTimeout(function () {
                        box.classList.add('visuallyhidden');
                        setViewButton(!viewbutton);
                      }, 250);
                      box.addEventListener(
                        'transitionend',
                        function (e) {
                          box.classList.add('hidden');
                        },
                        {
                          capture: false,
                          once: true,
                          passive: false,
                        }
                      );
                    }
                  }}
                >
                  Comprar
                </button>
                <br />
              </div>
              <div className="col-6">
                <a
                  className="btn btn-blue"
                  href={`https://api.whatsapp.com/send/?phone=56934529982&text=Hola, estoy cotizando el plan ${product.title} &app_absent=0`}
                >
                  <div style={{ display: 'flex' }} className="box-acciones">
                    <div>
                      <i class="fab fa-whatsapp"></i>
                    </div>
                    <div className="text-btn-financ">
                      Necesito Financiamiento Sendero
                    </div>
                  </div>
                </a>
                <div className="text-right">
                  <p>
                    <strong
                      style={{ textDecoration: 'underline' }}
                      href="#"
                      id="Tooltip"
                    >
                      ¿Qué es esto?
                    </strong>
                    .
                  </p>
                  <Tooltip
                    placement="bottom"
                    isOpen={tooltipOpen}
                    target="Tooltip"
                    toggle={() => settooltipOpen(!tooltipOpen)}
                  >
                    Financiamiento Sendero corresponde a una de las formas de
                    pago a las cuales usted podría optar para obtener su
                    producto de acuerdo a la cantidad de cuotas y tasas pactadas
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div
                  className="container-other-buttons visuallyhidden hidden"
                  id="box"
                >
                  {renderButtons()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ServiceCremacion />
    </>
  );
};
export default CompraLinea2;